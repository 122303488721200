// dependencies.
import React, { useEffect, useState } from 'react'
import EmailValidator from 'email-validator'
// components.
import Button from 'components/button'
// utils.
import { getCookie, setCookie } from 'src/js/utils/cookies'
// constants.
import { HELPERS_URL } from 'src/constants'

// Styles & Images:
import 'src/components/pages/exodus-pro/scss/FormSection.scss'

// Helpers:
/*
 * Related cookies
 * ex_pro_subscribe
 *  - set to "1" permanently if the user subscribes
 *  - set to "1" for 60 days if the user closed SubscribePopup
 *  - when set to "1" SubscribePopup will not be shown
 */
const isCookieSet = () => getCookie('ex_pro_subscribe') === '1'

// Main component:
const FormSection = ({ data }) => {
  const [submitted, setSubmitted] = useState(isCookieSet())
  const [disabled, setDisabled] = useState(true)
  const [values, setValues] = useState({ email: '', name: '' })

  const handleSubmit = () => {
    setDisabled(true)

    const { name, email } = values
    // Validate fields.
    if (!name || !email) {
      console.error('Missing data!!')
      return
    }
    if (name === '') {
      console.error('Invalid first name data!!')
      return
    }
    // Validate 'email' field.
    if (!EmailValidator.validate(email)) {
      console.error('Invalid email address!!')
      return
    }

    fetch(`${HELPERS_URL}/users/membership-subscription`, {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email, name }),
    })
      .then(async (response) => {
        if (response.status === 200) {
          setSubmitted(true)
          // Check and update cookies.
          if (!isCookieSet()) setCookie({ name: 'ex_pro_subscribe', value: '1' })
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    setDisabled(!values.name || !values.email)
  }, [values])

  return (
    <section className="x__exodus-pro-page__form" id="exodus-pro-form">
      <div className="x__exodus-pro-page__form__content">
        {submitted ? (
          <p
            className="x__exodus-pro-page__form__submitted__heading"
            dangerouslySetInnerHTML={{ __html: data.submitted.title }}
          />
        ) : (
          <h2
            className="x__exodus-pro-page__form__heading"
            dangerouslySetInnerHTML={{ __html: data.h2 }}
          />
        )}
        {submitted ? (
          <p
            className="x__exodus-pro-page__form__submitted__description"
            dangerouslySetInnerHTML={{ __html: data.submitted.copy }}
          />
        ) : (
          <p
            className="x__exodus-pro-page__form__description"
            dangerouslySetInnerHTML={{ __html: data.copy }}
          />
        )}
        {!submitted && (
          <form>
            <input
              className="x__input"
              type="text"
              placeholder="First Name"
              onChange={(e) => setValues({ ...values, name: e.target.value })}
              required
            />

            <input
              className="x__input"
              type="email"
              placeholder="Email Address"
              onChange={(e) => setValues({ ...values, email: e.target.value })}
              required
            />
            <Button
              copy={data.cta.copy}
              size="largest"
              type="pro"
              disabled={disabled}
              onClick={handleSubmit}
            />
          </form>
        )}
        {submitted ? (
          <Button
            copy={data.submitted.cta.copy}
            size="largest"
            type="pro"
            onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
          />
        ) : (
          <p
            className="x__exodus-pro-page__form__disclaimer"
            dangerouslySetInnerHTML={{ __html: data.disclaimer }}
          />
        )}
      </div>
    </section>
  )
}

export default FormSection
