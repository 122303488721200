// dependencies.
import React, { useState } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
// components.
import Button from 'components/button'
// utils.
import useScrollToAnchor from 'js/utils/hooks/useScrollToAnchor'

// Styles & Images
import 'src/components/pages/exodus-pro/scss/HeaderSection.scss'

const HeaderSection = ({ data, image }) => {
  const [buttonClicked, setButtonClicked] = useState(false)

  useScrollToAnchor(buttonClicked, 'exodus-pro-form', 100)

  const handleButtonClick = () => {
    setButtonClicked(true)
    setTimeout(() => setButtonClicked(false), 500)
  }

  return (
    <header className="x__exodus-pro-page__header">
      <div className="x__exodus-pro-page__header__content">
        <div className="x__exodus-pro-page__header__content--left">
          {data.h1 && (
            <h1
              className="x__exodus-pro-page__header__heading"
              dangerouslySetInnerHTML={{ __html: data.h1 }}
            />
          )}

          {data.h2 && (
            <h2
              className="x__exodus-pro-page__header__subheading"
              dangerouslySetInnerHTML={{ __html: data.h2 }}
            />
          )}

          {data.cta.copy && (
            <div className="x__exodus-pro-page__header__actions">
              <Button copy={data.cta.copy} size="largest" type="pro" onClick={handleButtonClick} />
            </div>
          )}
        </div>

        <div className="x__exodus-pro-page__header__content--right">
          {image && (
            <GatsbyImage
              image={image}
              alt={data.alt}
              loading="eager"
              className="x__exodus-pro-page__header__preview"
            />
          )}
        </div>
      </div>
    </header>
  )
}

export default HeaderSection
