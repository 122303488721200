// dependencies.
import React from 'react'

const iconFill = '#FFF'

const gradient = {
  l: ['#C2A6FF', '#8044FF'],
  d: ['#E79637', '#C84040'],
}

const icon = {
  trading: (
    <>
      <path
        d="M53 72.5V68M59 72.5V62M65 72.5V56M71 72.5v-21"
        stroke={iconFill}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
  fees: (
    <>
      <g clipPath="url(#c0)" stroke={iconFill}>
        <path d="M51 54v4c0 1.657 2.686 3 6 3s6-1.343 6-3v-4" />
        <path d="M51 58v4c0 1.657 2.686 3 6 3 1.537 0 2.938-.29 4-.765" />
        <path d="M51 62v4c0 1.657 2.686 3 6 3 1.537 0 2.939-.289 4-.764M57 57c3.314 0 6-1.343 6-3s-2.686-3-6-3-6 1.343-6 3 2.686 3 6 3zM61 62v4c0 1.657 2.686 3 6 3s6-1.343 6-3v-4" />
        <path d="M61 66v4c0 1.657 2.686 3 6 3s6-1.343 6-3v-4M67 65c3.314 0 6-1.343 6-3s-2.686-3-6-3-6 1.343-6 3 2.686 3 6 3z" />
      </g>
    </>
  ),
  rewards: (
    <>
      <path
        d="M62.673 57.681h0l6.152 3.571s0 0 0 0c.417.242.675.69.675 1.176v7.144c0 .486-.258.934-.675 1.176l-6.152 3.57.25.433-.25-.432a1.34 1.34 0 01-1.346 0l-.25.432.25-.432-6.152-3.571a1.36 1.36 0 01-.675-1.177V62.43s0 0 0 0c0-.487.258-.935.675-1.177 0 0 0 0 0 0l6.152-3.57h0a1.34 1.34 0 011.346 0z"
        stroke={iconFill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M69.37 49a.63.63 0 01.63.63v8.373l-.793.808a2.615 2.615 0 00-.52-.39l-.32-.183.633-.645V50H55v7.594l.632.644-.32.183c-.191.11-.365.24-.52.39L54 58.003V49.63a.63.63 0 01.63-.629h14.74z"
        fill={iconFill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M58 49h1v7.315l-1 .57V49zm8 7.886V49h-1v7.315l1 .57z"
        fill={iconFill}
      />
    </>
  ),
  support: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M63.564 50.056c-.14-.11-.437-.047-.827.099l-3.169 1.232-.2.096c-1.446.748-2.276 2.315-2.352 4.08v.277l-.066-.044a.343.343 0 00-.2-.05c-.386.033-.635.788-.556 1.685.077.882.443 1.57.822 1.56 0 2.039 1.98 5.012 4.984 5.012s5.004-2.974 5.003-5.012c.379.01.744-.678.821-1.56.08-.897-.17-1.652-.555-1.685a.384.384 0 00-.266.094v-.745L67 54.902c-.079-1.6-1.107-2.906-2.59-3.055l-.805-1.747-.04-.044zm-3.599 2.248l3.013-1.171.762 1.652.57.057.143.02c.897.17 1.55 1.062 1.55 2.234V58.99l-.005.17c-.109 1.818-1.946 3.841-3.998 3.841-2.113 0-3.984-2.145-3.984-4.01v-3.43l.016-.235c.121-1.32.772-2.41 1.766-2.94l.167-.083z"
        fill={iconFill}
      />
      <path
        d="M52.572 66.765c-.952.351-1.576 1.21-1.572 2.163v3.61c0 .807.655 1.462 1.463 1.462h19.074c.808 0 1.463-.655 1.463-1.463v-3.609c.003-.954-.62-1.812-1.572-2.163m-18.856 0l.16.474.007-.002m-.167-.472l5.265-1.777m-5.265 1.777l.173.47c-.002 0-.004 0-.006.002m0 0c-.768.286-1.242.965-1.239 1.69V72.538c0 .53.431.962.963.962h19.074a.963.963 0 00.963-.963v-3.61c.002-.725-.471-1.404-1.239-1.69m-18.522 0l4.959-1.674m13.563 1.674l.007.002.16-.474m-.167.472l-.006-.003.173-.469m-.167.472l-4.894-1.649m5.06 1.177l-5.206-1.754m0 0l.38.326a4.296 4.296 0 01-.234.251m-.146-.576c-.874 1.021-2.239 1.53-4.221 1.497m4.221-1.498l-.16.474.306.103m0 0c-1.003 1-2.46 1.452-4.375 1.421l.008-.5m0 0l-.008.5c-1.85-.03-3.287-.477-4.294-1.446M62 66.51c-1.921-.03-3.276-.527-4.163-1.52m0 0l.16.473-.3.101m.14-.575l-.373.333c.075.084.153.165.234.242"
        stroke={iconFill}
      />
    </>
  ),
  more: (
    <>
      <rect x={54.884} y={61.157} width={15} height={1} rx={0.5} fill={iconFill} />
      <rect
        width={15}
        height={1}
        rx={0.5}
        transform="matrix(0 1 1 0 61.884 54.157)"
        fill={iconFill}
      />
    </>
  ),
}

const FeatureIcon = ({ alt, name = 'more' }) => (
  <svg viewBox="0 0 124 124" fill="none">
    <g opacity={0.2} filter="url(#f0)">
      <circle cx={62} cy={62} r={42} fill={gradient.d[1]} />
    </g>
    <g filter="url(#f1)">
      <circle cx={62} cy={62} r={25} fill="url(#p0)" />
      <circle cx={62} cy={62} r={25} fill="url(#p1)" />
    </g>
    {icon[name]}
    <title>{alt}</title>
    <defs>
      <linearGradient id="p0" x1={89.5} y1={37} x2={41.5} y2={83.5} gradientUnits="userSpaceOnUse">
        <stop stopColor={gradient.l[0]} />
        <stop offset={1} stopColor={gradient.l[1]} />
      </linearGradient>
      <linearGradient
        id="p1"
        x1={82.5}
        y1={87}
        x2={49.36}
        y2={31.295}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={gradient.d[0]} />
        <stop offset={1} stopColor={gradient.d[1]} />
      </linearGradient>
      <filter
        id="f0"
        x={0}
        y={0}
        width={124}
        height={124}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation={10} result="effect1_foregroundBlur" />
      </filter>
      <filter
        id="f1"
        x={12}
        y={12}
        width={100}
        height={100}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation={12.5} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
      <clipPath id="c0">
        <path fill={iconFill} transform="translate(50 50)" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
)

export default FeatureIcon
