// dependencies.
import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
// components.
import FeatureIcon from 'src/components/pages/exodus-pro/img/FeatureIcon'

// Styles & Images:
import 'src/components/pages/exodus-pro/scss/FeaturesSection.scss'

// Main component:
const FeaturesSection = ({ data, image }) => (
  <section className="x__exodus-pro-page__features">
    <div className="x__exodus-pro-page__features__content">
      {data.h2 && (
        <h2
          className="x__exodus-pro-page__features__heading"
          dangerouslySetInnerHTML={{ __html: data.h2 }}
        />
      )}
      {data.items && data.items.length > 0 && (
        <div className="x__exodus-pro-page__features__items">
          {data.items.map(({ key, h3, copy }) => (
            <div key={key} className="x__exodus-pro-page__features__item">
              <FeatureIcon name={key} />
              <h3 dangerouslySetInnerHTML={{ __html: h3 }} />
              <p dangerouslySetInnerHTML={{ __html: copy }} />
            </div>
          ))}
        </div>
      )}
    </div>

    {image && (
      <GatsbyImage
        image={image}
        alt={data.alt}
        loading="lazy"
        className="x__exodus-pro-page__features__chrome"
      />
    )}
  </section>
)

export default FeaturesSection
